angular.module('enervexSalesappApp').factory('FittingMetaUtil', function FittingMetaUtil(_, Util, $q, Product, LayoutUtil) {
	return {
		assignedInlet: function(fittingMeta, ID, fitting) {
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			var found = _.find(centerPoints, function(centerPoint){
				return centerPoint.type == "section" && centerPoint.ID == ID
			})
			return (found) ? true : false
		},
		removeInvalidInlets: function(fittingMeta, inputs, fitting) {
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			_.each(centerPoints, function(centerPoint){
				if (centerPoint.type == "section" && centerPoint.ID && centerPoint.ID != '') {
					var found = _.find(inputs, function(input){
						return input.ID == centerPoint.ID
					})
					if (!found) {
						//cleaer
						centerPoint.ID = null
						centerPoint.GUIidentPath = null
					}
				}
			})
		},
		getCenterPoints:function(fittingMeta, fitting){
			var names = ['centerPointA', 'centerPointB', 'centerPointC', 'centerPointD']
			names = names.slice(0,fitting.secno)
			return _.map(names, function(name){
				if (!fittingMeta[name]) {
					fittingMeta[name] = {
						couplerType: "CONCENTRIC",
						type: "section"
					}
				}
				fittingMeta[name].label = name
				return fittingMeta[name]
			})
		},
		removeDuplicateAssignments:function(fittingMeta, inputs, fitting) {
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			_.each(inputs, function(input){
				var matches = _.filter(centerPoints, function(centerPoint){
					return centerPoint.type == "section" && centerPoint.ID == input.ID
				})
				_.each(matches, function(centerPoint, index){
					if (index != 0) {
						centerPoint.ID = null
						centerPoint.GUIidentPath = null
					}
				})
			})
		},
		hasDuplicateAssignments:function(v, fittingMeta, fitting, design, fittings) {
			var inputs = Util.getInlets(v, design)
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			_.each(inputs, function(input){
				var matches = _.filter(centerPoints, function(centerPoint){
					return centerPoint.type == "section" && centerPoint.ID == input.ID
				})
				_.each(matches, function(centerPoint, index){
					if (index != 0) {
						centerPoint.ID = null
						centerPoint.GUIidentPath = null
					}
				})
			})
		},
		assignInlet: function(fittingMeta, ID, GUIidentPath, fitting) {
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			var foundFree = _.find(centerPoints, function(centerPoint){
				if (centerPoint.type == "section" && (centerPoint.ID == '' || !centerPoint.ID)) {
					return true
				}
				if (centerPoint.type == "productSubtype" && !centerPoint.productSubtype) {
					return true
				}
			})
			if (foundFree) {
				foundFree.type = "section"
				foundFree.GUIidentPath = GUIidentPath
				foundFree.ID = ID
			} else {
				//pick off first product
				var foundUsed = _.find(centerPoints, function(centerPoint){
					if (centerPoint.type == "productSubtype") {
						return true
					}
				})
				if (foundUsed) {
					foundUsed.type = "section"
					foundUsed.GUIidentPath = GUIidentPath
					foundUsed.ID = ID
				}
			}
		},
		assignMissingInlets: function(fittingMeta, inputs, fitting) {
			var self = this
			var centerPoints = this.getCenterPoints(fittingMeta, fitting)
			_.each(inputs, function(input){
				if (!self.assignedInlet(fittingMeta, input.ID, fitting)) {
					//assign to first empty or "productSubtype"
					self.assignInlet(fittingMeta, input.ID, input.GUIidentPath, fitting)
				}
			})
		},
		getFit1: function(vent, fittings) {
			return _.find(fittings, function(vm){
				return (vm.code == vent.Fit1)
			})
		},
		setDefaultOupuut: function(fittingMeta, vent, fitting) {
			var path = "centerPoint" + (fitting.outletCenterpoint || "A");
			var centerpoint = fittingMeta[path]
			centerpoint.GUIidentPath = vent.GUIidentPath
			centerpoint.ID = vent.ID
		},
		resetFittingMeta: function(vent, design, fittings) {
			return this.getFittingMeta(vent, design, fittings, "resetFittingMeta")
		},
		setOverride: function(fittingMeta) {
			if (fittingMeta && !fittingMeta.override) {
				fittingMeta.override = {
					type: 'none',
					product: null,
					customProduct: null
				}
			}
		},
		getFittingMetas: function(design, fittings, target) {
			// console.log('getFittingMetas')
			if (!design) {
				return
			}
			if (!design.include.stack){
				return
			}
			var self = this
			// console.log('getFittingMetas() starting with '+ design.stack.fittingMetas.length)
			_.each(design.fc5layout.Layout, function(v){
				var fittingMeta = self.getFittingMeta(v, design, fittings, target)
			})
			// console.log('getFittingMetas() completed with '+ design.stack.fittingMetas.length)
		},
		scrubAutofitMode: function(fittingMeta) {
			if (!fittingMeta.autofitMode) {
				fittingMeta.autofitMode = (fittingMeta.autofit === false) ? "off" : "autofit";
			}
		},
		purgeFittingMetas: function(design, target) {
			if (!design || !design.stack) {
				return
			}
			if (!design.stack.fittingMetas) {
				design.stack.fittingMetas = []
			}
			design.stack.fittingMetas = _.filter(design.stack.fittingMetas, function(fittingMeta) {
				var found = _.find(design.fc5layout.Layout, function(v){
					return v.ID == fittingMeta.ID
				})
				if (found) {
					return true
				} else {
					// console.log('purgeFittingMetas() purging missing '+fittingMeta.ID)
					return false
				}
			})
			design.stack.fittingMetas = _.filter(design.stack.fittingMetas, function(fittingMeta, index) {
				var earlier = _.find(design.stack.fittingMetas, function(fittingMeta2, index2){
					if (index > index2 && fittingMeta.ID == fittingMeta2.ID) {
						// console.log("purgeFittingMetas() removing duplicate ID="+fittingMeta.ID + " at index "+ index)
						return true
					} else {
						return false
					}
				})
				if (earlier) {
					return false
				} else {
					return true
				}
			})
			// console.log('purgeFittingMetas() left '+ design.stack.fittingMetas.length)
		},
		getFittingMeta: function(vent, design, fittings, target) {
			try {
				return this._getFittingMeta(vent, design, fittings, target)
			} catch(e) {
				console.log("getFittingMeta() error on " + vent.GUIidentPath, e)
				throw e
			}
		},
		_getFittingMeta: function(vent, design, fittings, target) {
			var inlets = Util.getInlets(vent, design)
			var fitting = this.getFit1(vent, fittings)
			var to = vent
			var from = inlets && inlets.length > 0 ? inlets[0] : null

			var fittingMeta = _.find(design.stack.fittingMetas, function(fittingMeta){
				return vent.ID == fittingMeta.ID
			})

			if (!fittingMeta) {
				// console.log("_getFittingMeta() adding ID=" + vent.ID + " GUIidentPath=" +vent.GUIidentPath)
				fittingMeta = {
					ID: vent.ID,
					GUIidentPath: vent.GUIidentPath,
					centerPointA: {
						couplerType: "CONCENTRIC",
						type: "section"
					},
					centerPointB: {
						couplerType: "CONCENTRIC",
						type: "section"
					},
					centerPointC: {
						couplerType: "CONCENTRIC",
						type: "section"
					},
					centerPointD: {
						couplerType: "CONCENTRIC",
						type: "section"
					},
					autofitMode: "autofitComplete"
				}
				if (target == "stack") {
					fittingMeta.autofitMode = 'autofit'
				}
				design.stack.fittingMetas.push(fittingMeta)
				var fittingConfig = this.findFittingConfig(fittingMeta, fittings, design, vent)
				if (fittingConfig) {
					this.setFittingConfig(from, to, fittingConfig, fittings, design, fittingMeta, target)
					// console.log('new getFittingMeta set Fit1', to.Fit1, to.GUIidentPath)
					fitting = this.getFit1(vent, fittings)
				} else {
					//could be stack
					Util.setAutoFitting(vent, inlets, design, fittings)
					fitting = this.getFit1(vent, fittings)
					if (this.autofitAngles(vent, design, fittings, fittingMeta)) {
					} else {
						this.setDefaultOupuut(fittingMeta, vent, fitting)
					}
				}
				this.scrubAutofitMode(fittingMeta)
			} else  {
				if (target == "stack") {
					fittingMeta.autofitMode = 'autofit'
				}
				this.scrubAutofitMode(fittingMeta)
				if (fittingMeta.autofitMode == "autofitComplete" || fittingMeta.autofitMode == "autofit") {
					fittingMeta.centerPointA = {
						couplerType: "CONCENTRIC",
						type: "section"
					}
					fittingMeta.centerPointB = {
						couplerType: "CONCENTRIC",
						type: "section"
					}
					fittingMeta.centerPointC = {
						couplerType: "CONCENTRIC",
						type: "section"
					}
					fittingMeta.centerPointD = {
						couplerType: "CONCENTRIC",
						type: "section"
					}
					var fittingConfig = this.findFittingConfig(fittingMeta, fittings, design, vent, target)
					if (fittingConfig) {
						this.setFittingConfig(from, to, fittingConfig, fittings, design, fittingMeta, target)
						// console.log('existing getFittingMeta set Fit1', to.Fit1, to.GUIidentPath)
						fitting = this.getFit1(vent, fittings)
					} else {
						Util.setAutoFitting(vent, inlets, design, fittings, target)
						fitting = this.getFit1(vent, fittings)
						if (!this.autofitAngles(vent, design, fittings, fittingMeta)) {
							this.setDefaultOupuut(fittingMeta, vent, fitting)
						}
					}
				} else {
					//off
				}
			}
			var connections = [vent].concat(inlets)
			if (fitting) {
				this.removeInvalidInlets(fittingMeta, connections, fitting);
				this.removeDuplicateAssignments(fittingMeta, connections, fitting);
				this.assignMissingInlets(fittingMeta, connections, fitting);
			}
			return fittingMeta
		},
		getFittingType: function(fittingMeta, fittings, design, from, to, inlets, target) {
			var result = null
			if (!from || !to) {
				//ignore
			} else if (from.Fit1 == "STP" && inlets.length == 1) {
				result =  'startingPoint'
			} else {
				var toInlets = Util.getInlets(to, design)
				if (toInlets && toInlets.length == 1) {
					Util.setAutoFitting(to, toInlets, design, fittings, target)
					var angle = Math.round(Util.GetTrueAngle(from, to))
					var fromHorizontal = Util.axisChangeHorizontal(from, design)
					var toVertical = Util.axisChangeVertical(to, design)
					if (angle == 90) {
						if (fromHorizontal  && toVertical){
							//pure vertical
							if (LayoutUtil.allowsPureVertical(to, design)) {
								result =  'twoWayPureVerticalAllowed'
							} else {
								result =  'twoWayPureVertical'
							}
						} else {
							result =  'twoWayHorizontal'
						}
					} else {
						result =  'twoWayHorizontal'
					}
				} else if (toInlets && toInlets.length == 2) {
					result =  'threeWay'
				} else if (toInlets && toInlets.length == 3) {
					result =  'fourWay'
				}
			}
			// console.log("gettingType for "+ to.GUIidentPath + " as "+result)
			return result
		},
		findFittingConfig: function(fittingMeta, fittings, design, vent, target) {
			var inlets = Util.getInlets(vent, design)
			var to = vent
			var from = (inlets && inlets.length > 0) ? inlets[0] : null
			var fittingConfig = null

			if (fittingMeta.autofitMode == 'off') {
				return null
			} else if (fittingMeta.autofitMode == 'autofitComplete') {
				var fittingType = this.getFittingType(fittingMeta, fittings, design, from, to, inlets)
				switch (fittingType) {
					case "twoWayHorizontal":
						fittingConfig = design.application.properties && design.application.properties.twoWayFittingConfig
						break;
					case "twoWayPureVertical":
						fittingConfig = design.application.properties && design.application.properties.twoWayFittingConfigPureVertical
						break;
					case "twoWayPureVerticalAllowed":
						fittingConfig = design.application.properties && design.application.properties.twoWayFittingConfigPureVerticalAllowed
						break;
					case "startingPoint":
						fittingConfig = design.application.stack && design.application.stack.startingPointFittingConfig
						break;
					case "threeWay":
					default:
				}
				// console.log("findFittingConfig autofitComplete", vent.GUIidentPath, JSON.stringify(fittingConfig))
				return fittingConfig
			} else if (fittingMeta.autofitMode == 'autofit') {
				var fittingType = this.getFittingType(fittingMeta, fittings, design, from, to, inlets)
				var fitting = _.find(fittings, function(fitting){
					return fitting.code == vent.Fit1
				})
				if (!fitting) {
					// console.log("missing fitting for fit1"+vent.Fit1 + " on "+vent.GUIidentPath)
					return null
				}
				var config = _.find(fitting.fittingConfigs, function(o){
					var matchesApp = _.find(o.applications, function(a){
						return a == design.application._id
					})
					return matchesApp && o.type == fittingType
				})
				// console.log("findFittingConfig autofit", vent.GUIidentPath, JSON.stringify(config))
				return config
			}
		},
		hydrateFittingMeta: function(fittingMeta, design, clone, callback) {
			this.setOverride(fittingMeta)
			return $q(function(resolve, rejeect){
				if (fittingMeta.override.customProduct && !fittingMeta.override.customProduct._id) {
					var customProduct = _.find(design.customComponents, function(p){
						return fittingMeta.override.customProduct == p._id
					})
					if (customProduct) {
						fittingMeta.override.customProduct = clone ? _.clone(customProduct) : customProduct
					} else {
						//its been deleted, so remove it
						fittingMeta.override.customProduct = null
					}
				}
				var product = fittingMeta.override.product
				if (!product) {
					resolve(fittingMeta);
				} else if (product && product._id) {
					resolve(fittingMeta);
				} else {
					return Product.get({
						id: product
					}).$promise.then(function(item) {
						fittingMeta.override.product = clone ?  _.clone(item) : item;
						resolve(fittingMeta);
					})
				}
			})
		},
		dehydrateFittingMeta: function(fittingMeta) {
			this.setOverride(fittingMeta)
			if (fittingMeta.override.product && fittingMeta.override.product._id) {
				fittingMeta.override.product = fittingMeta.override.product._id
			}
			if (fittingMeta.override.customProduct && fittingMeta.override.customProduct._id) {
				fittingMeta.override.customProduct = fittingMeta.override.customProduct._id
			}
			// console.log("dehydrated", JSON.stringify(fittingMeta))
		},
		getClosest: function(used, all, inlets, to, centerpoint) {
			var available = _.filter(inlets, function(inlet){
				var existing = _.find(used, function(o){
					return o.ID == inlet.ID
				})
				return !existing
			})
			var sorted = _.sortBy(available, function(o){
				var angle = Util.GetTrueAngle(o, to)
				var delta = Math.abs(centerpoint.angle - angle)
				// console.log("angle " +o.GUIidentPath + " from " + to.GUIidentPath + " is "+angle + " with delta "+delta)
				return delta
			})
			return sorted[0]
		},
		autofitAngles: function(to, design, fittings, fittingMeta) {
			var self = this
			var inlets = Util.getInlets(to, design)
			var fitting = this.getFit1(to, fittings)
			if (fitting && inlets.length == (fitting.secno -1) && inlets.length > 0) {
				//assing all angles
				var used = []
				var labels = ["A","B", "C", "D"].slice(0,fitting.secno)
				var outletLabel = fitting.outletCenterpoint
				var outletAngle = fitting.angles["centerPoint"+outletLabel]
				var all = _.map(labels, function(label){
					var angle = fitting.angles["centerPoint"+label]
					var delta = outletAngle - angle
					if (delta < 0) {
						delta = 180 + delta
					}
					return {
						label: label,
						angle: delta
					}
				})
				var outletMeta = _.find(all, function(o){
					return o.label == outletLabel
				})
				outletMeta.ID = to.ID
				outletMeta.GUIidentPath = to.GUIidentPath
				used.push(outletMeta)
				all = _.sortBy(all, function(o){
					return o.angle
				})
				// console.log("all ", all)
				_.each(all, function(centerpoint){
					if (centerpoint.ID) {
						//continue
						return true
					}
					var inlet = self.getClosest(used, all, inlets, to, centerpoint)
					centerpoint.ID = inlet.ID
					centerpoint.GUIidentPath = inlet.GUIidentPath
					used.push(centerpoint)
				})
				// console.log("computed centerpoints", used)
				_.each(used, function(o){
					var path = "centerPoint"+o.label
					fittingMeta[path].type = "section"
					fittingMeta[path].ID = o.ID
					fittingMeta[path].GUIidentPath = o.GUIidentPath
				})
				return true
			}
		},
		setFittingConfig:function(from, to, fittingConfig, fittings, design, fittingMeta, target){
			if (!fittingConfig) {
				return
			}
			if (fittingConfig.fitting) {
				var fitting = _.find(fittings, function(f){
					return f._id == fittingConfig.fitting
				})
				if (fitting) {
					Util.setFit1(to, fitting.code, design)
				}
			}
			if (fittingConfig.centerpointCover){
				var path= "centerPoint" + fittingConfig.centerpointCover
				fittingMeta[path].productSubtype = fittingConfig.cover
				fittingMeta[path].type = "productSubtype"
			}
			if (fittingConfig.centerpointInlet){
				var path= "centerPoint" + fittingConfig.centerpointInlet
				fittingMeta[path].GUIidentPath = from.GUIidentPath
				fittingMeta[path].ID = from.ID
				fittingMeta[path].type = "section"
			}
			if (fittingConfig.centerpointOutlet){
				var path= "centerPoint" + fittingConfig.centerpointOutlet
				fittingMeta[path].GUIidentPath = to.GUIidentPath
				fittingMeta[path].ID = to.ID
				fittingMeta[path].type = "section"
			}
			//if already exists, then remove
			design.stack.accessoryMetas = _.filter(design.stack.accessoryMetas, function(meta){
				if (meta.ID == to.ID && meta.source == "automation") {
					return false
				} else {
					return true
				}
			})
			_.each(fittingConfig.accessories, function(productSubtype){
				design.stack.accessoryMetas.push({
					GUIidentPath: to.GUIidentPath,
					ID: to.ID,
					productSubtype: productSubtype,
					quantity: 1,
					source: "automation"
				})
			})
		},
		validateVent:function(v, fittingMeta, design, fittings) {
			var errors = {
				centerPointA:{},
				centerPointB:{},
				centerPointC:{},
				centerPointD:{},
			}
			var fitting = _.find(fittings, function(fitting){
				return fitting.code == v.Fit1
			})
			var centerpoints = this.getCenterPoints(fittingMeta, fitting)
			var allSections = [v].concat(Util.getInlets(v, design))
			var missingAssingment = _.find(allSections, function(v2){
				var assigned = _.find(centerpoints, function(cp){
					return cp.type == "section" && cp.GUIidentPath == v2.GUIidentPath
				})
				if (!assigned){
					return true
				}
			})
			_.each(centerpoints, function(cp){
				var name = cp.label
				if (cp.type == "productSubtype") {
					if (!(cp.productSubtype && cp.productSubtype != '')) {
						errors[cp.label].productSubtype = "Missing cover assignement"
					} else if (missingAssingment) {
						errors[cp.label].productSubtype = "Missing section assignment for " + missingAssingment.GUIidentPath
					}
				}
				if (cp.type == "section") {
					if (!(cp.GUIidentPath && cp.GUIidentPath != '')) {
						errors[cp.label].GUIidentPath = "Missing section assignement"
					}
					var duplicate = _.find(centerpoints, function(cp2){
						return cp2.type == "section" && cp2.GUIidentPath == cp.GUIidentPath && cp != cp2
					})
					if (duplicate) {
						errors[cp.label].GUIidentPath = "Duplicate section assignement"
					}
				}
			})
			return errors
		},
		validateVentAsMessage:function(v, fittingMeta, design, fittings) {
			var error = this.validateVent(v, fittingMeta, design, fittings)
			var messages = []
			_.each(["centerPointA","centerPointB","centerPointC","centerPointD"], function(name){
				var prop = error[name]
				_.each(["productSubtype", "GUIidentPath"], function(name2){
					var subprop = prop[name2]
					if (subprop){
						messages.push(subprop + " for "+ name)
					}
				})
			})
			if (messages.length > 0) {
				return messages.join(". ")
			} else {
				return null
			}
		}
	}
})
